@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  @apply font-roman;
}

b,
strong {
  @apply font-bold;
}

.react-datepicker__day-name {
  @apply !text-default-400;
}

.react-datepicker__day {
  @apply !text-foreground;
}

.react-datepicker__header {
  @apply !bg-transparent !border-none;
}

.react-datepicker-popper {
  @apply !z-20;
}

.react-datepicker__day:hover {
  @apply !bg-default-200 !rounded-full;
}

.react-datepicker__day--disabled {
  @apply !text-default-300;
}

.react-datepicker__day--selected {
  @apply !bg-primary !text-primary-foreground !rounded-full;
}

.react-datepicker__day--keyboard-selected {
  @apply !bg-default-200 !rounded-full;
}

.react-datepicker__time {
  @apply !bg-content1 !text-foreground;
}

.react-datepicker-time__header {
  @apply !text-default-500;
}

.react-datepicker__time-container {
  @apply !border-default-400;
}

.react-datepicker__time-list-item:hover {
  @apply !bg-default-200 !rounded-full;
}

.react-datepicker__time-list-item--selected {
  @apply !bg-primary !text-primary-foreground !rounded-full;
}

._contentEditable_w1wlt_339 {
  @apply h-52 overflow-y-scroll;
}

div[data-rmiz-modal-overlay="visible"] {
  @apply bg-overlay/80;
}

img[data-rmiz-modal-img] {
  @apply cursor-[inherit];
}

button[data-rmiz-btn-unzoom] {
  @apply z-50 cursor-pointer hover:bg-content1 hover:text-foreground;
}

.ProseMirror p.is-editor-empty:first-child::before {
  @apply pointer-events-none h-0 text-default-400 float-left;
  content: attr(data-placeholder);
}

.ProseMirror a {
  @apply pointer-events-none;
}

.ProseMirror:focus {
  @apply outline-none;
}
